//BRAND
export const name = 'AiCourse';
export const company = 'Spacester';
export const websiteURL = 'http://localhost:3000';
export const serverURL = 'http://localhost:5000';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/aicourse-7b5c3.appspot.com/o/logo512.png?alt=media&token=2c2f4dd3-e3dd-4bb7-b1cd-29098608cf24';
export const razorpayEnabled = false;
export const paypalEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 99;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "O Gerador de Cursos de IA revolucionou meu processo de criação de conteúdo, fornecendo tópicos precisos e relevantes sem esforço. É uma potência que economiza tempo e aprimora a qualidade e relevância dos meus cursos. Uma ferramenta indispensável para educadores em busca de eficiência e experiências de aprendizado online impactantes.";
export const from = "Anam Meena Sharif";
export const profession = 'CFO at Spacester';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";
